"use client";

import { Button, Heading, Link, ImageWrapper } from "ui-components";

export default function GlobalError({
  _error,
  reset,
}: {
  _error: Error & { digest?: string };
  reset: () => void;
}): JSX.Element {
  return (
    <html lang="en">
      <body>
        <Heading>Something went wrong!</Heading>
        <ImageWrapper
          src="/not-found.png"
          width={555}
          height={500}
          alt="Picture of the author"
        />
        <Link href="/">Home Page</Link>
        <Button onClick={() => reset()}>Try again</Button>
      </body>
    </html>
  );
}
